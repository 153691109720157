<template lang="pug">
    div.pa-10#produto
        PageTitle(label="Pedidos")

        div(v-acl.cant.hide='`order.list`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='`order.list`')
            v-data-table( 
                fixed-header
                :headers="headers"
                :items="orders.data"
                :items-per-page="orders.per_page"
                class="elevation-1"
                :loading='orders.data.length <= 0'
                loading-text="Carregando... Aguarde!"
                hide-default-footer
                )
                
                template(v-slot:item.created_at="{ item }")
                    small(style="white-space:nowrap") {{item.created_at | formatDate}}
                
                template(v-slot:item.client.phone="{ item }")
                    span(style="white-space:nowrap") {{item.client.phone | phoneFormat}}

                template(v-slot:item.total="{ item }") {{item.total | money}}
                
                template(v-slot:item.status="{ item }")
                    v-menu(offset-y)
                        template(v-slot:activator="{ on, attrs }")
                            v-btn(small rounded v-bind="attrs" v-on="on" :color="colorStatus(item)")
                                span {{statusLabel(item)}}
                            
                        v-list
                            v-list-item(v-for="(status, key) in listStatus" :key="key" v-show="item.status != status.type && acl(`order.status.${status.type}`)" @click="changeStatus(item.id, status.type)" disable)
                                v-list-item-title {{status.label}}
                
                template(v-slot:item.courier="{ item }")
                    v-menu(offset-y)
                        template(v-slot:activator="{ on, attrs }")
                            v-btn(small rounded v-bind="attrs" v-on="on" color="primary")
                                span(v-if="!item.courier") --
                                span(v-if="item.courier") 
                                    v-icon(
                                        small
                                        class="mr-2"
                                    ) mdi-moped-outline
                                    |{{item.courier.fullname}}
                            
                        v-list
                            v-list-item(key="courier.id_0" v-show="item.courier" @click="changeCourier(item.id, 0)")
                                v-list-item-title --
                            v-list-item(v-for="(courier) in couriers" :key="courier.id" v-show="!item.courier || item.courier.id !== courier.id" @click="changeCourier(item.id, courier.id)")
                                v-list-item-title {{courier.fullname}}

                
                template(v-slot:item.actions="{ item }")      
                    div(style="white-space:nowrap")          
                        v-icon(
                            small
                            class="mr-2"
                            @click="print(item)"
                            target="_blank"
                            title="Imprimir"
                            v-acl.can.disable='`order.print`'
                        ) mdi-printer

                        v-icon(
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            title="Editar"
                            v-acl.can.disable='`order.edit`'
                        ) mdi-pencil
                        
                        //- v-icon(
                        //-     small
                        //-     @click="deleteItem(item)"
                        //-     title="Deletar"
                        //- )  mdi-delete
            paginate( :data="orders" )
            confirm(ref="confirm")

</template>

<script>
export default {
    name: "OrdersList",
    components:{
        confirm: () => import( '@/components/Confirm' ),
        paginate: () => import( '@/components/Paginate' ),
        PageTitle: () => import( '@/components/PageTitle' ),
    },

    data(){
        return {
            params: {},
            headers: [
                {
                    text: '#',
                    value: 'id',
                    sortable: false,
                    width: '1%',
                },
                {   text: 'DATA', value: 'created_at', align: 'center', sortable: false, width: '1%',  },
                {   text: 'CLIENTE', value: 'client.fullname', sortable: false  },
                {   text: 'TELEFONE', value: 'client.phone', align: 'center', sortable: false, width: '1%',  },
                {   text: 'STATUS', value: 'status', align: 'center', sortable: false, width: '1%',  },
                {   text: 'ENTREGADOR', value: 'courier', align: 'center', sortable: false, width: '1%',  },
                {   text: 'TOTAL', value: 'total', align: 'center', sortable: false, width: '1%',  },
                {   text: '', value: 'actions', sortable: false, width: '1%', },
            ],
            orders: {
                data: []
            },
            couriers: []
        }
    },

    created: function() {
        this.params = {...this.$route.query}// clone prevent bug
        this.loadData()
    },

    methods: {
        loadData: async function(){
            
            if ( this.acl('order.list') ) {
                let orders = await this.api.get( 'orders', this.params )
                this.orders = orders.data

                let couriers = await this.api.get( 'couriers', {per_page: 'all'} )
                this.couriers = couriers.data
            }
            

        },

        editItem: function( item ){
            this.$router.push( { name: 'orders-edit', params: { id: item.id } })
        },

        // deleteItem: async function( item ){
        //     if( await this.$refs.confirm.open( 'Confirmação de Exclusão', 'Deseja realmente excluir o item selecionado?' ) ){
        //         console.log( 'deletando item:', item )
        //     }else{
        //         console.log( 'desistiu de deletar o item' )
        //     }
        // },

        changeStatus: function( id, status ){
            let self = this
            this.api.put( `order/${id}/status`, {status})
            .then(function( res ){
                if( res ){
                    self.$root.toast.show( {message: res.data.message} )
                    self.loadData()
                }                
            })
        },

        changeCourier: function( id, courier_id ){
            let self = this
            this.api.put( `order/${id}/courier`, {courier_id})
            .then(function( res ){
                if( res ){
                    self.$root.toast.show( {message: res.data.message} )
                    self.loadData()
                }                
            })
        },

        print: async function ( item ){
            window.open(`${this.api.url}/order/${item.id}/print`, "_blank"); 
        },

        colorStatus: function( item ){
            let color = '';

            switch( item.status ){
                case 0:
                default:
                    color = 'gray';
                    break;

                case 1:
                    color = 'success';
                    break;
                
                case 2:
                    color = 'error';
                    break;
                
                case 3:
                    color = 'info';
                    break;
                    
            }
            return color;
        },
        statusLabel(item){
            const status = this.config.ORDER_STATUS.find(status => status.type === item.status)
            return status.label || '';
        }
    },

    computed: {
        listStatus: function() {
            const list = this.config.ORDER_STATUS;
            return list.sort((a, b) => a.order > b.order ? 1 : -1 );
        },
        
    },

};
</script>
